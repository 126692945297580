/* c8 ignore next 42 */
import { useState } from 'react'
import { getAuth } from '../Providers/AuthProvider'
import * as Sentry from '@sentry/react'
import { GetDataProps } from './types'

export const useGetData = ({ resource }: GetDataProps) => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<unknown>(null)

  const fetchData = async (id: number | null) => {
    try {
      const token = await getAuth().auth0Client.getTokenSilently()
      const response = await fetch(
        `${import.meta.env.VITE_MX8_SURVEY_PROTOCOL ?? 'https'}://${import.meta.env.VITE_MX8_ADMIN_API_DOMAIN}/v1/${resource}/${id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      )

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`)
      }

      const result = await response.json()
      setData(result)
    } catch (err) {
      setError(err)
      Sentry.captureException(err)
    } finally {
      setIsLoading(false)
    }
  }

  return { data, isLoading, error, fetchData }
}
