import {
  Alert,
  AlertTitle,
  Box,
  LinearProgress,
  Typography,
} from '@mui/material'
import Markdown from 'markdown-to-jsx'
import {
  Identifier,
  Labeled,
  Link,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  useGetOne,
  useRecordContext,
  useStore,
} from 'react-admin'
import { insightsContainer } from './styles'
import { ReportingElementProps } from '../../Reports/types'
import * as Sentry from '@sentry/react'
import { useLocation } from 'react-router'

/**
 * useGetOneInsight is the hook for getting a insight
 * @param id - the id of the insight to get
 * @returns - the insight, loading state
 */
const useGetOneInsight = (id: Identifier, dataset: string) => {
  const { data, isLoading, error, refetch } = useGetOne(
    `insights/${dataset}`,
    { id: id },
    { enabled: !!dataset },
  )
  /* c8 ignore next 13 */
  if (
    data &&
    !isLoading &&
    !error &&
    data?.insight_report?.status == 'pending'
  ) {
    // refetch every 3 second
    setTimeout(() => {
      refetch()
    }, 3000)
  }

  return { data, isLoading, error }
}

export const InsightsReport: React.FC<ReportingElementProps> = (props) => {
  const location = useLocation()
  const isProjectInsights = location.pathname.includes('project_insights')

  /* c8 ignore next */
  if (!props.reportId) return
  const { data, isLoading, error } = useGetOneInsight(
    props.reportId,
    props.dataset,
  )
  /* c8 ignore next 40 */
  if (isLoading || !data?.markdown) {
    return <LinearProgress />
  }

  if (error) {
    Sentry.captureMessage(`failed: ${error}`, 'info')
    return (
      <Alert severity="error" data-testid="report-error">
        <AlertTitle>Unable to retrieve insights</AlertTitle>
      </Alert>
    )
  }

  return (
    <Box sx={insightsContainer(props.InsightCardStyle, props.isInsightSurvey)}>
      <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
        {!isProjectInsights ? (
          <>
            Survey :
            <Link
              to={`/surveys/${data?.survey?.id}/`}
              sx={{ fontSize: 14, ml: 1, fontWeight: 500 }}
            >
              {data?.survey?.name}
            </Link>
          </>
        ) : (
          <>Insights :</>
        )}
      </Typography>
      <Box paddingLeft="12px" paddingTop="20px">
        <Typography fontWeight={900} fontSize={14.5}>
          {data?.question}
        </Typography>
        <Markdown className="markdown">{data?.markdown}</Markdown>
      </Box>
    </Box>
  )
}

export const InsightShow = ({ ...props }) => {
  const insight = useRecordContext()

  const [currentDataset] = useStore(
    /* c8 ignore next */
    `current-survey-${insight?.survey?.id}`,
  )
  return (
    <Show {...props} data-testid="insight-test">
      <SimpleShowLayout>
        <Labeled>
          <TextField source="question" />
        </Labeled>
        <Labeled>
          <ReferenceField label="Survey" source="survey.id" reference="surveys">
            <TextField source="name" />
          </ReferenceField>
        </Labeled>
        <InsightsReport
          /* c8 ignore next */
          reportId={insight?.id}
          source="insights"
          dataset={currentDataset}
        />
      </SimpleShowLayout>
    </Show>
  )
}
