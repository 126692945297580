import { Dispatch, FC, SetStateAction, useState } from 'react'
import { ThemeType } from '../../../../types'
import { useFormContext } from 'react-hook-form'
import { Box, Button, IconButton, Paper, Popover } from '@mui/material'
import { TextInput } from 'react-admin'
import { Mx8Theme } from '../../../../../../layout'
import { CloseOutlined } from '@mui/icons-material'
import { ChromePicker } from 'react-color'

const ColorPicker: FC<{
  color: string
  setCustomTheme: Dispatch<SetStateAction<ThemeType>>
  themeColor: 'main_color' | 'secondary_color' | 'error_color'
  label: string
  disabled: boolean
}> = ({ color, setCustomTheme, themeColor, label, disabled }) => {
  const { setValue } = useFormContext()
  const [openPicker, setOpenPicker] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const id = openPicker ? 'simple-popper' : undefined

  return (
    <Box display="flex" gap="15px" justifyContent="space-between">
      <TextInput
        fullWidth
        label={label}
        source={`theme.${themeColor}`}
        value={color}
        disabled={disabled}
      />
      <Box>
        <Button
          sx={{
            minWidth: 0,
            backgroundColor: color,
            width: '38px',
            height: '38px',
            border: `1px solid ${Mx8Theme.palette.text.secondary}`,
          }}
          data-testid={`${label}-pick`}
          disabled={disabled}
          /* c8 ignore next 4 */
          onClick={(e) => {
            setOpenPicker((prev) => !prev)
            setAnchorEl(e.currentTarget)
          }}
          aria-describedby={id}
        />
        <Popover
          id={id}
          open={openPicker}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          data-testid="color-picker-container"
        >
          <Paper>
            <IconButton
              sx={{ display: 'block', marginLeft: 'auto' }}
              /* c8 ignore next */
              onClick={() => setOpenPicker(false)}
            >
              <CloseOutlined />
            </IconButton>
            <ChromePicker
              color={color}
              /* c8 ignore next 6 */
              onChange={(color) => {
                setValue(`theme.${themeColor}`, color.hex, {
                  shouldDirty: true,
                })
                setCustomTheme((prev) => ({ ...prev, [themeColor]: color.hex }))
              }}
            />
          </Paper>
        </Popover>
      </Box>
    </Box>
  )
}

export default ColorPicker
