import {
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  required,
} from 'react-admin'
import { Box } from '@mui/system'
import { CHECKBOX_DATA } from '../List/index'

const SubscriptionForm = () => (
  <SimpleForm>
    <TextInput
      id="name_Subscription"
      source="name"
      label="Name"
      fullWidth
      validate={required()}
    />
    <NumberInput
      source="max_surveys_per_project"
      label="Max Surveys Per Project"
      fullWidth
      validate={required()}
    />
    <NumberInput
      source="max_users_per_account"
      label="Max Users Per Account"
      fullWidth
      validate={required()}
    />
    <NumberInput
      source="max_projects_per_account"
      label="Max Projects Per Account"
      fullWidth
      validate={required()}
    />
    <TextInput
      source="cost_per_complete"
      label="Cost Per Project"
      fullWidth
      validate={required()}
      /* c8 ignore next 3 */
      format={(v) => (v ? `$${v}` : '')}
      parse={(v) => v.replace('$', '')}
    />
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: 2,
      }}
    >
      {CHECKBOX_DATA.map((checkbox) => (
        <BooleanInput
          key={checkbox.field}
          source={checkbox.field}
          label={checkbox.label}
          fullWidth
        />
      ))}
    </Box>
  </SimpleForm>
)

export default SubscriptionForm
