import { TableResizeObjectType } from '../../utils/types'

const SURVEY_WARNING =
  'The survey is curently updating and cannot be edited until the update is complete.'

const SURVEY_SUCCESS =
  'The survey is curently fielding and has limited editing capabilities.'

const DEFAULT_SURVEY = `from survey import Survey

s = Survey(**globals())

#### DEMOS SCREENER

age = s.numeric_question(
    question="How old are you?",
    min_max=(18, 65),
    recodes={
        "18-24": "18-24",
        "25-34": "25-34",
        "35-44": "35-44",
        "45-54": "45-54",
        "55-64": "55-64",
        "65+": "65+",
    },
)

s.select_question(
    "What is your gender?", ["Male", "Female", "Non-Binary", "Prefer not to say"]
)

s.select_question(
    "What is your ethnicity?",
    [
        "White",
        "Black",
        "Other",
    ],
)

s.select_question("Would you describe yourself as Hispanic?", ["Yes", "No"])

s.numeric_question(
    "What is your annual income?",
    min_max=(0, 500000),
    recodes={
        "0-20000": "Less than $20,000",
        "20000-34999": "$20,000 to $34,999",
        "35000-49999": "$35,000 to $49,999",
        "50000-74999": "$50,000 to $74,999",
        "75000-99999": "$75,000 to $99,999",
        "100000-149999": "$100,000 to $149,999",
        "150000+": "$150,000 or more",
    },
)

s.select_question(
    "What is your highest level of education?",
    [
        "Less than high school degree",
        "High school graduate",
        "Some college",
        "Bachelor's degree",
        "Master's degree",
        "Post-graduate degree",
    ],
)
    
region = s.select_question("Which region of the country do you come from?", options=["Northeast", "Midwest","South", "West"])

#### FULL SURVEY STARTS HERE

s.complete()
`

const DUMMY_QUESTION =
  "We're conducting a research project on consumer opinions for our client, a well known organisation. To gather information, we'll ask questions about you, your lifestyle, and preferences. \n\nIf you want us to delete them, you can just visit mx8.io/mydata. \n\nIf you agree to these terms, we can proceed. \n\nAre you happy to proceed?"

const TABLE_RESIZE_OBJECT: TableResizeObjectType = {
  isScreenBelow400Value: {
    calculateHeightsInights: `calc(160vh - 198px)`,
    calculateHeightList: `calc(90.2vh - 155px)`,
  },
  isScreenBetween400And600Value: {
    calculateHeightsInights: `calc(120vh - 195px)`,
    calculateHeightList: `calc(55.1vh - 155px)`,
  },
  isScreenAbove600Value: {
    calculateHeightsInights: `calc(96vh - 198px)`,
    calculateHeightList: `calc(32.5vh - 100px)`,
  },
}

export {
  DEFAULT_SURVEY,
  DUMMY_QUESTION,
  SURVEY_SUCCESS,
  SURVEY_WARNING,
  TABLE_RESIZE_OBJECT,
}
