import { IconButton } from '@mui/material'
import { useSimpleFormIteratorItem } from 'react-admin'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown'
import { useFormContext } from 'react-hook-form'
import { ReOrderButtonsProps, ReportQuestionQtopicTypes } from '../../types'
import { FC } from 'react'

export const ReOrderButtons: FC<ReOrderButtonsProps> = ({ nestedKey }) => {
  const { index, total, reOrder } = useSimpleFormIteratorItem()

  const { setValue, getValues } = useFormContext()

  const handleReOrder = async (
    fromIndex: number,
    dynamicOperator: 'up' | 'down',
  ) => {
    const formData = getValues()
    const reportQuestions = formData[nestedKey][0]?.report_questions

    const targetIndex = dynamicOperator === 'up' ? fromIndex - 1 : fromIndex + 1

    // Swap the sort_order
    const currentItem = reportQuestions[fromIndex]
    const targetItem = reportQuestions[targetIndex]

    ;[currentItem.sort_order, targetItem.sort_order] = [
      targetItem.sort_order,
      currentItem.sort_order,
    ]

    reOrder(targetIndex)
    // Access the nested structure
    const nestedData: ReportQuestionQtopicTypes = formData[nestedKey]

    // Update the sort_order in the nested array
    const updatedData = nestedData.map((report) => ({
      ...report,
      report_questions: report.report_questions.map((question) => {
        if (question.id === currentItem.id) {
          return {
            ...question,
            sort_order: currentItem.sort_order,
            reporting_style: currentItem.reporting_style,
          }
        }
        if (question.id === targetItem.id) {
          return {
            ...question,
            sort_order: targetItem.sort_order,
            reporting_style: targetItem.reporting_style,
          }
        }
        return question
      }),
    }))
    // Set the updated value back into the form
    setValue(nestedKey, updatedData)
  }
  return (
    <>
      <IconButton
        size="small"
        onClick={() => handleReOrder(index, 'up')}
        disabled={index <= 0}
        sx={{ color: '#317BFA', rotate: '180deg' }}
        data-testid={`up-${index}`}
      >
        <ExpandCircleDownIcon />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleReOrder(index, 'down')}
        disabled={index >= total - 1}
        sx={{ color: '#317BFA' }}
        data-testid={`down-${index}`}
      >
        <ExpandCircleDownIcon />
      </IconButton>
    </>
  )
}
