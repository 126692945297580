import {
  DatagridConfigurable,
  ExportButton,
  List,
  maxLength,
  ReferenceInput,
  required,
  SelectColumnsButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  useNotify,
} from 'react-admin'
import { EditInDialogButton } from '@react-admin/ra-form-layout'
import { successHandler } from '../../../utils'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
interface UserListActionsProps {
  notify: (message: string, options?: object) => void
}

const SuperUserAccountListActions = () => (
  <TopToolbar sx={{ width: '100%' }}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Typography variant="h6" component="h2" sx={{ fontSize: 17 }}>
        Accounts
      </Typography>

      <Box>
        <SelectColumnsButton />
        <ExportButton />
      </Box>
    </Box>
  </TopToolbar>
)

const UserListData: React.FC<UserListActionsProps> = (props) => {
  const { isLoading, isFetching } = useListContext()
  const { notify } = props
  const onSuccess = successHandler('superuser', 'updated', 'list', notify)

  return (
    <DatagridConfigurable isLoading={isLoading || isFetching} rowClick="">
      <TextField source="id" label="ID" />
      <TextField source="name" label="Name" />
      <TextField source="subscription_name" label="Subscription Name" />
      <EditInDialogButton
        fullWidth
        resource="superuser/accounts"
        mutationMode="pessimistic"
        mutationOptions={{ onSuccess }}
        data-testid="account-edit"
      >
        <SimpleForm resource="superuser/accounts">
          <TextInput
            source="name"
            label="Name"
            fullWidth
            validate={required()}
            data-testid="account-name"
          />
          <ReferenceInput
            source="subscription_id"
            reference="superuser/subscriptions"
          >
            <SelectInput
              optionText="name"
              fullWidth
              validate={[required(), maxLength(200)]}
            />
          </ReferenceInput>
        </SimpleForm>
      </EditInDialogButton>
    </DatagridConfigurable>
  )
}

export const SuperUserAccountList = ({ ...props }) => {
  const notify = useNotify()
  return (
    <List
      {...props}
      resource="superuser/accounts"
      data-testid="super-user-account-list"
      actions={<SuperUserAccountListActions />}
      sort={{ field: 'id', order: 'DESC' }}
      disableSyncWithLocation
      queryOptions={{ refetchInterval: 6000 }}
    >
      <UserListData notify={notify} {...props} />
    </List>
  )
}
