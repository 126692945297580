import { useGetOne } from 'react-admin'

export const reportInsighStatusCheckfun = (
  source: string,
  currentDataset: string,
  id: number,
) => {
  /* c8 ignore next 1 */
  const dataSetHandling = currentDataset ? currentDataset : 'simulated'
  const { data } = useGetOne(`${source}/${dataSetHandling}`, { id: id })

  const isInsightDataLoading =
    /* c8 ignore next 2 */
    data?.insight_report?.status === 'pending' ||
    data?.insight_report?.status === 'error'

  const isReportDataLoading =
    data?.cross_tab?.status === 'pending' || data?.cross_tab?.status === 'error'
  const shouldShowLoading = isInsightDataLoading || isReportDataLoading
  return {
    reportInsightDataSetData: data,
    shouldShowLoading: shouldShowLoading,
  }
}
