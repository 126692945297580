import React from 'react'
import { NumberInput, NumberField } from 'react-admin'
import { PriceInputProps } from '../../types'

const PriceInput: React.FC<PriceInputProps> = ({
  isSuperUser = false,
  sourcePrefix,
  sx,
}) => {
  const renderSuperUserInput = () => (
    <NumberInput
      data-testid="number-input"
      source={`${sourcePrefix}price`}
      sx={{ width: '100%', marginTop: '1rem', ...sx }}
      label="Price"
    />
  )

  const renderRegularUserInput = () => (
    <NumberField
      data-testid="number-field"
      source={`${sourcePrefix}price`}
      sx={{ width: '100%', ...sx }}
      variant="h6"
      label="$"
    />
  )

  return isSuperUser ? renderSuperUserInput() : renderRegularUserInput()
}

export default PriceInput
