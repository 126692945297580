import { required, SelectInput } from 'react-admin'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { themeData } from '../../../utils/constants'
import { FC } from 'react'
import { Subscription } from '../../../hooks/types'

interface SelectOrientationProps {
  subscription: Subscription
}

const SelectOrientation: FC<SelectOrientationProps> = ({ subscription }) => {
  const { setValue } = useFormContext()
  /* c8 ignore next 7 */
  const handlOrientaton = (orientationName: string) => {
    const updatedTheme = { ...themeData, orientation: orientationName }
    setValue('theme', updatedTheme)
  }
  const methods = useForm({
    defaultValues: themeData,
  })
  let choices = []

  /* c8 ignore next 9 */
  if (subscription?.allow_landscape) {
    choices = [
      { id: 'portrait', name: 'Portrait' },
      { id: 'landscape', name: 'Landscape' },
    ]
  } else {
    choices = [{ id: 'portrait', name: 'Portrait' }]
  }

  return (
    <FormProvider {...methods}>
      <SelectInput
        source="theme.orientation"
        fullWidth
        className="white-bg"
        label="Orientation"
        choices={choices}
        data-testid="create-orientation"
        defaultValue={'portrait'}
        /* c8 ignore next 1 */
        onChange={(e) => handlOrientaton(e.target.value)}
        validate={required()}
      />
    </FormProvider>
  )
}

export default SelectOrientation
