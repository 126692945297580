const MODAL_DESCRIPTION =
  'The survey version saved locally a few minutes ago is not synced with the server version. We’ve restored the local version, but please note that saving it now may overwrite changes made by other users.'

export const themeData = {
  main_color: '#49AE8A',
  secondary_color: '#FFFFFF',
  error_color: '#FF6F61',
  icon_image: '/themes/shanna/shanna.jpeg',
  line_1: 'Talking with',
  line_2: 'Shanna Researcher',
  background_image: '/themes/shanna/background.jpeg',
  orientation: 'portrait',
  redirect_message: "We'll send you back in {seconds} seconds...",
  disqualification_message:
    "We're sorry but you don't qualify for this survey today.",
  hints: {
    waiting: 'Shanna Researcher is Typing...',
    multichoice: 'Select your option...',
    multiselect: 'Select your options...',
    others: 'Enter your message...',
  },
}

const TEMPLATE_CHOICE = [
  {
    id: 'shanna',
    value: 'shanna',
    icon_image: '/themes/shanna/shanna.jpeg',
    background_image: '/themes/shanna/background.jpeg',
    line_2: 'Shanna Researcher',
    waiting: 'Shanna Researcher is Typing...',
  },
  {
    id: 'chipmunk',
    value: 'chipmunk',
    icon_image: '/themes/chipmunk/chipmunk.png',
    background_image: '/themes/chipmunk/chipmunk-bg.jpeg',
    line_2: 'Chipmunk Researcher',
    waiting: 'Chipmunk Researcher is Typing...',
  },
  {
    id: 'john',
    value: 'john',
    icon_image: '/themes/john/john.png',
    background_image: '/themes/john/john-bg.jpeg',
    line_2: 'John Researcher',
    waiting: 'John Researcher is Typing...',
  },
  {
    id: 'robo',
    value: 'robo',
    icon_image: '/themes/robo/robo.png',
    background_image: '/themes/robo/bg.jpeg',
    line_2: 'Robo Researcher',
    waiting: 'Robo Researcher is Typing...',
  },
]

const WARNING_SURVEY_CREATE = `Please edit the survey code or provide a description to create a survey.`

const FILE_TYPES = {
  spss: {
    name: 'SPSS',
    extension: 'sav',
    contentType: 'application/x-spss-sav',
    path_template: 'surveys/{dataset}/spss',
  },
  excel_long: {
    name: 'excel_long',
    extension: 'xlsx',
    contentType:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    path_template: 'surveys/{dataset}/excel',
  },
  excel_wide: {
    name: 'excel_wide',
    extension: 'xlsx',
    contentType:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    path_template: 'surveys/{dataset}/excel_wide',
  },
  excel: {
    name: 'Excel',
    extension: 'xlsx',
    contentType:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    path_template: 'surveys/{dataset}/excel',
  },
  report: {
    name: 'excel',
    extension: 'xslx',
    contentType:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    path_template: 'reports/{dataset}/excel',
  },
  translations: {
    name: 'Translations',
    extension: 'xslx',
    contentType:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    path_template: 'translations/download',
  },
  all_report: {
    name: 'Reports',
    extension: 'xslx',
    contentType:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    path_template: 'reports/{dataset}/excel/survey',
  },
}
const REPORTS_DOWLOAD_LIST = [
  { id: 'all_report', name: 'All Reports in Excel' },
  { id: 'excel_wide', name: 'Raw Data in Excel (Wide)' },
  { id: 'excel_long', name: 'Raw Data in Excel (Long)' },
  { id: 'spss', name: 'SPSS File' },
]

const SIGNIFICANCE_DATA = [
  ['Residual t-test', 'residual_t'],
  ['Column t-test', 'column_t'],
  ['Row t-test', 'row_t'],
]

const CONFIDENCE_DATA = [
  ['0.90', '0.90'],
  ['0.95', '0.95'],
  ['0.99', '0.99'],
]

const LINE_COLORS = [
  '#3FD29C',
  '#317CFE',
  '#A3BAE6',
  '#FF6F61',
  '#FFF7ED',
  '#DBD2C8',
  '#FFFFFF',
  '#212324',
  '#586680',
]

export {
  CONFIDENCE_DATA,
  FILE_TYPES,
  LINE_COLORS,
  MODAL_DESCRIPTION,
  REPORTS_DOWLOAD_LIST,
  SIGNIFICANCE_DATA,
  TEMPLATE_CHOICE,
  WARNING_SURVEY_CREATE,
}
