import {
  CheckCircleOutlineSharp,
  CloseOutlined,
  ErrorOutline,
} from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
} from '@mui/material'
import { Identifier, RaRecord, useRecordContext } from 'ra-core'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import { Mx8Theme } from '../../../../layout'
import {
  cardContainer,
  creditCardForm,
  modalContainer,
  statusContainer,
} from './style'
import { getAuth } from '../../../../Providers/AuthProvider'
import { TokenResult } from '../../../../utils/types'
import ReactGA from 'react-ga4'
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk'
import { PaymentDetailsType, PaymentModalProps } from '../../types'
import * as Sentry from '@sentry/react'
export const handleCreditCardTranscation = async (
  cardToken: TokenResult,
  audienceId: Identifier | undefined,
  setPaymentDetails: Dispatch<SetStateAction<PaymentDetailsType>>,
) => {
  /* c8 ignore next 3 */
  if (!audienceId) return
  const token = await getAuth().auth0Client?.getTokenSilently()
  const apiProtocol = import.meta.env.VITE_MX8_ADMIN_API_PROTOCOL ?? 'https'
  const apiUrl = `${apiProtocol}://${import.meta.env.VITE_MX8_ADMIN_API_DOMAIN}/v1/products/buy/audience/${audienceId}?payment_token=${cardToken.token}`

  if (cardToken.status === 'OK') {
    /* c8 ignore next 4 */
    setPaymentDetails((prev: PaymentDetailsType) => ({
      ...prev,
      isLoading: true,
    }))
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const data = await response.json()

      if (response.ok) {
        /* c8 ignore next 5 */
        setPaymentDetails((prev: PaymentDetailsType) => ({
          ...prev,
          isLoading: false,
          isSuccess: true,
        }))
        ReactGA.send({ payment_successfull: data?.message })
        ReactGA.event({
          category: 'payment done',
          action: 'payment done',
          label: data.message,
          value: data.id,
        })
      } else {
        /* c8 ignore next 5 */
        setPaymentDetails((prev) => ({
          ...prev,
          isLoading: false,
          isSuccess: true,
          isError: data.message,
        }))
      }
      /* c8 ignore next 9 */
    } catch (error) {
      Sentry.captureException(error)
      const err = error as { message: string; errors: { status: string } }
      setPaymentDetails({
        isLoading: false,
        isSuccess: false,
        isError: err?.errors?.status ?? err?.message,
      })
    }
  }
}

export const renderPaymentStatus = (
  paymentDetails: PaymentDetailsType,
  record: RaRecord<Identifier> | undefined,
  setPaymentDetails: Dispatch<SetStateAction<PaymentDetailsType>>,
) => {
  if (paymentDetails.isSuccess) {
    return (
      <Box sx={statusContainer} data-testid="success-container">
        <CheckCircleOutlineSharp
          sx={{
            fontSize: '3rem',
            color: Mx8Theme.palette.success.main,
          }}
          data-testid={`success-Icone`}
        />
        <Typography data-testid={`success-header`}>
          Payment successful
        </Typography>
      </Box>
    )
  } else if (paymentDetails.isLoading) {
    return (
      <Box sx={statusContainer} data-testid="loading-container">
        <Box>
          <Typography textAlign="center" id="modal-modal-title">
            Please wait!!
          </Typography>
          <Typography textAlign="center">Transaction is in progress</Typography>
        </Box>
        <CircularProgress
          id="modal-modal-description"
          data-testid={`Loader-Ui`}
          size="3rem"
        />
      </Box>
    )
  } else if (paymentDetails.isError.length) {
    return (
      <Box sx={statusContainer} data-testid="error-container">
        <ErrorOutline
          id="modal-modal-title"
          sx={{ fontSize: '3rem', color: Mx8Theme.palette.error.main }}
        />
        <Typography id="modal-modal-description" textAlign="center">
          {paymentDetails.isError}
        </Typography>
      </Box>
    )
  } else {
    return (
      <Box data-testid="card-container">
        <Typography fontSize="18px" textAlign="center">
          A fee of{' '}
          <Typography
            id="modal-modal-title"
            fontSize="inherit"
            fontWeight="600"
            sx={{
              color: Mx8Theme.palette.primary.main,
              display: 'inline-block',
            }}
          >
            {/* c8 ignore next  */}${record?.price}
          </Typography>{' '}
          will be charged to the card below to put this audience live. Please
          review and confirm your payment details to proceed.
        </Typography>
        <Box sx={creditCardForm}>
          <PaymentForm
            locationId={`${import.meta.env.VITE_PAYMENT_GATEWAY_LOCATIONID}`}
            applicationId={`${import.meta.env.VITE_PAYMENT_GATEWAY_APPLICATIONID}`}
            /* c8 ignore next 10 */
            cardTokenizeResponseReceived={async (cardToken) => {
              handleCreditCardTranscation(
                cardToken,
                record?.id,
                setPaymentDetails,
              )
            }}
          >
            <CreditCard
              buttonProps={{
                css: {
                  backgroundColor: Mx8Theme.palette.primary.main,
                  fontSize: '20px',
                  color: 'background.paper',
                  '&:hover': {
                    boxShadow: 'none',
                  },
                },
              }}
            />
          </PaymentForm>
        </Box>
      </Box>
    )
  }
}

const PaymentModal: FC<PaymentModalProps> = ({ open, handleClose }) => {
  const initialPaymentDetails = {
    isLoading: false,
    isError: '',
    isSuccess: false,
  }
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetailsType>(
    initialPaymentDetails,
  )

  const record = useRecordContext()
  const handleModalClose = () => {
    setPaymentDetails(initialPaymentDetails)
    handleClose()
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalContainer}>
        <Box data-testid="credit-card-comp" width="100%" sx={cardContainer}>
          <IconButton
            onClick={handleModalClose}
            sx={{ marginLeft: 'auto', display: 'flex' }}
            data-testid="payment-modal-close-btn"
          >
            <CloseOutlined />
          </IconButton>
          {renderPaymentStatus(paymentDetails, record, setPaymentDetails)}
        </Box>
      </Box>
    </Modal>
  )
}

export default PaymentModal
