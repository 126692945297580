// Insight Edit component

import { Grid2 } from '@mui/material'
import {
  Edit,
  SimpleForm,
  maxLength,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useNotify,
  useRedirect,
  useRefresh,
  useRecordContext,
} from 'react-admin'
import { useLocation } from 'react-router-dom'
import { successHandler } from '../../../utils'
import { MarkdownInput } from '@react-admin/ra-markdown'

export const InsightEditor = () => {
  const record = useRecordContext()
  const location = useLocation()
  const isProjectInsights = location.pathname.includes('project_insights')

  return (
    <Grid2 width="100%" container spacing={2}>
      <Grid2 size={{ xs: 12 }}>
        <TextInput
          id="insights_question"
          source="question"
          fullWidth
          validate={[required(), maxLength(200)]}
          data-testid={'question'}
        />
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        <MarkdownInput
          id="insights_detailed_brief"
          source="detailed_brief"
          fullWidth
          height={'15rem'}
          validate={maxLength(200000)}
          toolbarItems={[
            ['heading', 'bold', 'italic'],
            ['ul', 'ol', 'task', 'indent', 'outdent'],
            ['table'],
          ]}
          hideModeSwitch
          label={
            <span style={{ fontSize: '1.2rem', fontWeight: 600 }}>
              Detailed Brief
            </span>
          }
          data-testid="detailed-brief"
        />
      </Grid2>
      {!isProjectInsights && (
        <Grid2 size={{ xs: 12 }}>
          <ReferenceInput label="survey" source="survey.id" reference="surveys">
            <SelectInput
              id="insights_survey"
              label="Survey"
              optionText="name"
              fullWidth
              defaultValue={record?.survey_id}
              validate={required()}
            />
          </ReferenceInput>
        </Grid2>
      )}
    </Grid2>
  )
}

export const InsightEdit = ({ ...props }) => {
  const onSuccess = successHandler(
    'Insight',
    'updated',
    'show',
    useNotify(),
    useRedirect(),
    useRefresh(),
  )

  return (
    <Edit {...props} mutationMode="pessimistic" mutationOptions={{ onSuccess }}>
      <SimpleForm>
        <InsightEditor />
      </SimpleForm>
    </Edit>
  )
}
