import { FC } from 'react'
import {
  Box,
  Grid2,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { ReferenceManyInput } from '@react-admin/ra-relationships'

import {
  FormDataConsumer,
  RadioButtonGroupInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  useDelete,
  useRecordContext,
} from 'react-admin'
import { FieldValues, useFormContext } from 'react-hook-form'
import { RemoveCircleOutline } from '@mui/icons-material'
import {
  AnalysisContainerProps,
  Option,
  QuestionConfigItem,
  QuestionConfigOption,
} from '../../types'
import ReportStyles from '../ReportStyles'
import { ReOrderButtons } from './Component/ReOrderButton'

/* c8 ignore next 16 */
const DeleteQuestion: FC<{ id: number }> = ({ id }) => {
  const [update, { isPending }] = useDelete('report_questions', {
    id,
  })

  return (
    <IconButton
      data-testid={`delete-${id}`}
      size="small"
      disabled={isPending}
      onClick={() => update()}
    >
      <RemoveCircleOutline color="error" />
    </IconButton>
  )
}

const AnalysisContainer: FC<AnalysisContainerProps> = (props) => {
  const {
    name,
    reference,
    showOption,
    title,
    isNoneOption,
    subscription,
    setValidationForReportQuestion,
    nestedKey,
  } = props
  const record = useRecordContext()
  const resposiveUITablet = useMediaQuery('(max-width:900px)')
  /* c8 ignore next 11 */
  const renderOption = (
    option: Option,
    scopedFormData: FieldValues | undefined,
  ) => {
    return (
      <ReportStyles
        option={option}
        scop_style={scopedFormData?.reporting_style}
      />
    )
  }

  const radioOptions = [
    { id: 'row', name: 'Row' },
    {
      id: 'column',
      name: 'Column',
    },
    { id: 'page', name: 'Page' },
    isNoneOption ? { id: 'none', name: 'None' } : {},
  ].filter(Boolean)

  const radioOptionsWithoutPage = [
    { id: 'row', name: 'Row' },
    {
      id: 'column',
      name: 'Column',
    },
    isNoneOption ? { id: 'none', name: 'None' } : {},
  ].filter(Boolean)

  const { getValues } = useFormContext()

  const isTopic = name === 'topic'
  /* c8 ignore next 24 */
  const customValidator = () => {
    const formData = getValues()
    const reportQuestions =
      formData['@@ra-many/reports/report_questions/report_id'][0]
        ?.report_questions

    if (reportQuestions) {
      let pageCount = 0
      let rowCount = 0

      reportQuestions.forEach((item: { reporting_style: string }) => {
        if (item?.reporting_style === 'page') pageCount++
        if (item?.reporting_style === 'row') rowCount++
      })

      if (pageCount > 1) {
        setValidationForReportQuestion(true)
        return 'Page not allowed more than one'
      } else if (rowCount === 0) {
        setValidationForReportQuestion(true)
        return 'At least one row required'
      }
    }
  }

  return (
    <Grid2 size={{ xs: 12 }} data-testid={`${title}-analysis`}>
      <Typography sx={{ color: 'grey' }}>{title}s</Typography>
      <ReferenceManyInput
        key={`${reference}-element`}
        reference={reference}
        target="report_id"
        perPage={1000}
        label=""
        filter={
          /* c8 ignore next */
          name === 'reporting_label' ? { id: record?.selected_questions } : {}
        }
        sort={{ field: 'sort_order', order: 'ASC' }}
      >
        <SimpleFormIterator
          inline
          fullWidth
          disableRemove
          disableReordering
          disableAdd
          disableClear
        >
          <FormDataConsumer data-testid="analysis-form">
            {/* c8 ignore next 72 */}
            {({ scopedFormData }) => {
              const config: QuestionConfigItem = scopedFormData?.config
                ? scopedFormData?.config[0]
                : {}
              return (
                <Grid2 container width="100%">
                  <Grid2 size={{ xs: 4 }} marginRight={5}>
                    <TextInput
                      id={title}
                      label={title}
                      readOnly={true}
                      source={name}
                      sx={{ mt: 3 }}
                      fullWidth
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 'auto' }} marginRight={3}>
                    <Box
                      sx={{
                        width: resposiveUITablet ? '70%' : '100%',
                      }}
                    >
                      <RadioButtonGroupInput
                        key="radio-btn-group"
                        helperText={false}
                        label={'Reporting Style*'}
                        source="reporting_style"
                        choices={
                          subscription?.allow_paged_reporting
                            ? radioOptions
                            : radioOptionsWithoutPage
                        }
                        defaultValue="none"
                        sx={customStyleForIcones}
                        optionText={(option) =>
                          renderOption(option, scopedFormData)
                        }
                        validate={[customValidator]}
                      />
                    </Box>
                  </Grid2>

                  {showOption && config?.options && (
                    <Grid2 size={{ xs: 3 }} marginRight={3}>
                      <SelectInput
                        id="config-options"
                        source="config[0].value"
                        choices={Array.from(config?.options).map(
                          (option: QuestionConfigOption) => ({
                            id: option.value,
                            name: option.label,
                          }),
                        )}
                        label="Options"
                        sx={{ mt: 3 }}
                        validate={required()}
                      />
                    </Grid2>
                  )}
                  <Grid2
                    alignSelf="center"
                    marginLeft="auto"
                    marginBottom="7px"
                  >
                    <ReOrderButtons nestedKey={nestedKey} />

                    {!isTopic && <DeleteQuestion id={scopedFormData?.id} />}
                  </Grid2>
                </Grid2>
              )
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ReferenceManyInput>
    </Grid2>
  )
}

export default AnalysisContainer

const customStyleForIcones = {
  '& .MuiSvgIcon': {
    display: 'none',
    // important: 'true',
  },
  '& .MuiFormGroup-root': {
    border: '1px solid',
    borderColor: 'grey.400',
    boxSizing: 'initial',
  },
  '& .MuiTypography-root': {
    lineHeight: '0.5',
    important: 'true',
  },
  '& .MuiFormControlLabel-root': {
    margin: '3px',
    important: 'true',
  },
  '& .MuiSvgIcon-fontSizeMedium': {
    width: '22px',
    height: '19px',
    padding: '4.5px',
  },
  '& .MuiRadio-root': {
    display: 'none',
  },
  '& .MuiFormLabel-root': {
    marginBottom: '-6px',
    color: 'grey.500',
    marginLeft: '10px',
  },
  '& legend span': {
    backgroundColor: 'label.primary',
    padding: '0 5px',
  },
  '& .MuiFormHelperText-root': {
    display: 'none',
  },
}
